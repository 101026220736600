import React, { useEffect } from "react";
import {NextUIProvider} from "@nextui-org/react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { Index } from "./pages/index";
import { PrivacyPolicy } from './pages/privacy_policy';
import { TermsOfService } from './pages/terms_of_service';

function App() {
	const navigate = useNavigate();

	useEffect(() => {
        const root = document.documentElement;
        // Always apply these classes
        root.classList.add('dark', 'text-foreground', 'bg-background');

        // Cleanup function removes 'dark' but leaves others
        return () => {
            root.classList.remove('dark', 'text-foreground', 'bg-background');
        };
    }, []);

	// useEffect(() => {
    //     const root = document.documentElement;
    //     // Always apply these classes
    //     root.classList.add('light', 'text-foreground', 'bg-default-100');

    //     // Cleanup function removes 'dark' but leaves others
    //     return () => {
    //         root.classList.remove('light', 'text-foreground', 'bg-default-100');
    //     };
    // }, []);

	return (
		<NextUIProvider navigate={navigate}>
			<Routes>
				<Route path='/' element={ <Index /> } />
				<Route path='/privacy-policy' element={ <PrivacyPolicy /> } />
				<Route path='/terms-of-service' element={ <TermsOfService /> } />

				<Route path='*' element={<Index />} />
			</Routes>
		</NextUIProvider>
	);
}

export default App;
