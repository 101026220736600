import React, { useState, useEffect, useRef } from "react";
// import { useNavigate, useLocation } from 'react-router-dom';

import {Link} from "@nextui-org/react";

export function TermsOfService() {
    // useEffect(() => {
    // }, []);

    return (
        <>
            <div className="p-6">
                <p>Terms of Service</p>
                <p className="text-xs text-default-500">Last Updated: 07/04/24</p>

                <div className="">
                    <Link href="/">Home</Link>
                </div>

                <div className="mt-28">
                    Terms of Service Last Updated: 7/4/2024 Welcome to oz.xyz ("we," "our," "us"). These Terms of Service ("Terms") govern your use of our website and services. 1. Acceptance of Terms By accessing or using our service, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you do not have permission to access the service. 2. Use of Our Service You agree to use our service only for lawful purposes and in accordance with these Terms. You agree not to: Use our service in any way that violates any applicable local, state, national, or international law or regulation. Engage in any conduct that restricts or inhibits anyone's use or enjoyment of the service. 3. Accounts When you create an account, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account and password. 4. Intellectual Property Our service and its original content, features, and functionality are and will remain the exclusive property of OzDAO, Inc and its licensors. 5. Termination We may terminate or suspend your account immediately, without prior notice or liability, for any reason, including if you breach the Terms. 6. Limitation of Liability In no event shall OzDAO, Inc, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses. 7. Governing Law These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its conflict of law provisions. 8. Changes to Terms We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. 9. Contact Us If you have any questions about these Terms, please contact us at: admin@oz.xyz
                </div>
            </div>
        </>
    )
}

