import React, { useState, useEffect, useRef } from "react";
// import { useNavigate, useLocation } from 'react-router-dom';

import {Link, Card, CardHeader, CardBody, CardFooter} from "@nextui-org/react";

export function Index() {
    // useEffect(() => {
    // }, []);

    return (
        <>
            <div className="p-6 flex flex-col items-center">
                <p className="text-2xl bg-clip-text text-transparent bg-gradient-to-br from-purple-500 to-cyan-400">OZDAO</p>
                <div className="space-x-4 overflow-x-auto">
                    <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://x.com/oz_dao">Twitter</Link>
                    <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAxNjg1OTIzMjYz">Telegram</Link>
                    <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://blog.oz.xyz">Blog</Link>
                    <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://opensea.io/collection/ozdao">Opensea</Link>
                </div>

                <div className="space-y-4 mt-10">
                    <Card className="w-full sm:w-[400px]">
                        <Link href="https://wandbot.app" className="no-underline hover:no-underline focus:no-underline">
                            <CardBody>
                                <div className="flex flex-col">
                                    <p>WandBot</p>
                                    <p className="text-default-500 text-sm">The Crypto Social Data Trading Platform</p>
                                </div>
                            </CardBody>
                        </Link>

                        <CardFooter className="space-x-4 overflow-x-auto">
                            <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://x.com/wand_bot">Twitter</Link>
                            <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.me/+vhLia5ksuYYzNGJh">Telegram</Link>
                            <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://blog.oz.xyz/introducing-wandbot-part-2-0f00e3aeeeed">Medium</Link>
                            <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.wandbot.app/">Gitbook</Link>
                            <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://wandbot.app/">Website</Link>
                        </CardFooter>
                    </Card>

                    <Card className="w-full sm:w-[400px]">
                        <Link href="https://totoml.app" className="no-underline hover:no-underline focus:no-underline">
                            <CardBody>
                                <div className="flex flex-col">
                                    <p>TOTO</p>
                                    <p className="text-default-500 text-sm">An API for Crypto Twitter</p>
                                </div>
                            </CardBody>
                        </Link>
                        <CardFooter className="space-x-4 overflow-x-auto">
                            <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://x.com/toto_api">Twitter</Link>
                            <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.me/+RRFM0b-zCyJiMTcx">Telegram</Link>
                            <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.totoml.app">Gitbook</Link>
                            <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://totoml.app">Website</Link>
                        </CardFooter>
                    </Card>

                    <Card className="w-full sm:w-[400px]">
                        <Link href="https://ozkey.app" className="no-underline hover:no-underline focus:no-underline">
                            <CardBody>
                                <div className="flex flex-col">
                                    <p>OzKey</p>
                                    <p className="text-default-500 text-sm">Seamless Token Gating for Telegram</p>
                                </div>
                            </CardBody>
                        </Link>

                        <CardFooter className="space-x-4 overflow-x-auto">
                            <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://x.com/OzKeyBot">Twitter</Link>
                            <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://ozkey.app/">Website</Link>
                        </CardFooter>
                    </Card>
                </div>

                <div className="space-x-4 mt-10">
                    <Link href="/privacy-policy">Privacy Policy</Link>
                    <Link href="/terms-of-service">Terms of Service</Link>
                </div>
            </div>
        </>
    )
}

