import React, { useState, useEffect, useRef } from "react";
// import { useNavigate, useLocation } from 'react-router-dom';

import {Link} from "@nextui-org/react";

export function PrivacyPolicy() {
    // useEffect(() => {
    // }, []);

    return (
        <>
            <div className="p-6">
                <p>Privacy Policy</p>
                <p className="text-xs text-default-500">Last Updated: 07/04/24</p>

                <div className="">
                    <Link href="/">Home</Link>
                </div>

                <div className="mt-28">
                    Welcome to oz.xyz ("we," "our," "us"). We are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, and disclose information about you. 1. Information We Collect We collect several types of information, including: Personal Information: Name, email address, phone number, etc. Usage Data: IP address, browser type, pages visited, time and date of visit, etc. Cookies and Tracking Technologies: Information collected through cookies and other tracking technologies. 2. How We Use Your Information We use the information we collect for various purposes, including: To provide and maintain our service To notify you about changes to our service To allow you to participate in interactive features To provide customer support To gather analysis or valuable information to improve our service To monitor the usage of our service To detect, prevent, and address technical issues 3. Sharing Your Information We do not share your personal information with third parties except as described in this Privacy Policy: With Service Providers: To assist us in providing our service. For Legal Reasons: If required by law or to protect and defend our rights. 4. Security of Your Information We use appropriate security measures to protect your information. However, no method of transmission over the Internet or method of electronic storage is 100% secure. 5. Your Data Protection Rights You have rights including: The right to access The right to rectification The right to erasure The right to restrict processing The right to object to processing The right to data portability 6. Changes to This Privacy Policy We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. 7. Contact Us If you have any questions about this Privacy Policy, please contact us at: admin@oz.xyz
                </div>
            </div>
        </>
    )
}